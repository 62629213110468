/* Main page */
.main-title {
  font-weight: 450;
  font-size: 24px;
  text-align: center;
}

.main-button {
  background: #131685 !important;
  border-color: #131685 !important;
  border-radius: 30px !important;
  font-weight: bold !important;
}

/* Welcome page */
.welcome-container {
  min-height: 100vh;
  min-width: 100vw;
  background: linear-gradient(#FFFFFF 40%, #FFD100 70%);
}

.welcome-logo {
  max-width: 300px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.welcome-title {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.welcome-page-title {
  font-size: 36px;
  line-height: 40px;
}

.welcome-product-img {
  display: block;
  margin: auto;
}

/* Login, register, verification page */
.auth-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
}

.auth-subtitle {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
}

.submit-btn {
  background: #001489 !important;
  border-radius: 6px !important;
  font-weight: bold !important;
  border-color: initial !important;
}

/* Topbar */
.topbar {
  background: linear-gradient(180.05deg, #FFD100 61.28%, rgba(255, 255, 255, 0) 99.96%);
  height: 15vh;
}

.topbar-logo {
  width: 100px;
}

.abbott-topbar-logo {
  float: right;
  width: 90px;
}

/* Homepage */
.carousel-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 0px;
  text-align: left;
  color: #FFD100;
}

.carousel-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-align: left;
}

.carousel .carousel-indicators button {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.carousel .carousel-indicators button.active {
  background-color: #001489;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.homepage-card {
  background: linear-gradient(269.44deg, rgba(255, 209, 0, 0) 59.26%, #FFD100 83.89%), #FFFFFF;
  border: 0.75641px solid rgba(0, 20, 137, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
}

.homepage-card-img {
  width: 40px;
  height: 40px;
}

.homepage-card-logo {
  width: 65px;
  height: 40px;
}

/* How it works components */
.how-it-works-img {
  width: 100px;
  display: block;
  margin: auto;
}

.how-it-works-title {
  font-size: 11px;
  line-height: 10px;
  text-align: center;
}

/* Pages */
.page-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  color: #001489;
}

.upload-receipt-icon {
  width: 30px;
  height: 30px;
}

.input-fields {
  border: 1px solid #E65621 !important;
  border-radius: 6px !important;
}

.receipt-product-container {
  width: 150px;
  margin: auto;
}

.orange-btn {
  background: #E65621 !important;
  border-radius: 6px !important;
  font-weight: bold !important;
  border-color: initial !important;
}

.grey-btn {
  background: #6C6C6C !important;
  border-radius: 6px !important;
  font-weight: bold !important;
  border-color: initial !important;
}

.receipt-confirmation-details {
  font-size: 17px;
  line-height: 15px;
  text-align: right;
}

.receipt-product-purchased {
  font-family: Verdana;
  font-weight: bold;
  font-size: 14px;
  color: #E65621;
}

.modal-back-drop {
  background-color: #E65621 !important;
}

.receipt-image-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: #FFFFFF;
}

.rewards-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 80%;
  margin: auto;
}

.rewards-title {
  font-size: 16px;
  line-height: 15px;
  margin: auto;
}

.rewards-number {
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  color: #E65621;
}

.rewards-subtitle {
  font-size: 14px;
  line-height: 13px;
}

.rewards-progess-bar {
  height: 10px !important;
}

.rewards-progess-bar .progress-bar {
  background-color: #E65621;
  border-radius: 20px;
}

.faq-card {
  border: 1px solid rgba(0, 20, 137, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.faq-arrow-icon {
  width: 15px;
  height: 15px;
  margin-top: -10px;
}

.carousel-icon {
  background: #FFFFFF;
  border: 0.5px solid #CDCDCD;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.selected {
  background: #001489;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  padding: 10px 0px 10px 0px;
}

.non-selected {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  padding: 10px 0px 10px 0px;
}

.rewards-container {
  height: 55vh;
  overflow: auto;
}

.rewards-small-title {
  font-size: 8px;
  line-height: 6px;
  text-align: end;
}

.rewards-page-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 90%;
  margin: auto;
}

.tng-title {
  font-size: 18px;
  line-height: 17px;
}

.tng-reward-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 23px;
  text-align: center;
  color: #E65621;
}

.tng-title-background {
  background: linear-gradient(90deg, #E65621 -6.61%, rgba(230, 86, 33, 0) 94.58%);
}

.tng-code {
  font-weight: bold;
  font-size: 36px;
  line-height: 15px;
  text-align: center;
}

.address-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #001489;
}

.address-subtitle {
  font-size: 12px;
  line-height: 14px;
  color: #6C6C6C;
}

/* ADMIN */
#admin-login {
  background-color: #FFD100;
  height: 100vh;
  width: 100vw;
  padding-top: 35vh;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
}

#admin-login #admin-login-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

#admin-login #admin-login-container #admin-image-container {
  color: white;
  font-weight: bold;
  margin-right: 5vw;
}

#admin-login #admin-login-container #admin-image-container #image-container {
  max-width: 500px;
}

#admin-login #admin-login-container #admin-login-form {
  background-color: white;
  max-width: 400px;
  padding: 30px;
}

#react-pro-sidebar .pro-sidebar-inner {
  background-color: #FFD100;
  color: white;
}

.navbar {
  background-color: #FFD100;
  color: white;
}

.admin-page {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.admin-body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}

.admin-content {
  max-height: 93vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 25px;
}

.admin-content .card-header,
.admin-content .card-footer {
  color: white;
  background-color: #FFD100;
}